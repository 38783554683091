
    import logo from '../img/logo.png'
    import router from "@/router";
    import { reactive ,ref } from 'vue'
    import {createI18n, useI18n} from "vue-i18n";
    import i18n from '@/i18n'
    import vueI18n from "vue-i18n"
    import store from '@/store'
    import {routeLocationKey} from "vue-router";
    import footerbottomlogo from '../img/footerbottomlogo.png'

    export default {
        name: "indexSy",
        computed:{

        },
        setup(){
            // const {locale,t}=useI18n({
            //     locale:'EN',
            //     messages:{
            //         CN:require('@/language/CN')
            //     }
            // })
            // router.push('/i')
            // if(window.screen.availWidth<900){
            //     router.push('app')
            // }
            const activeIndex = ref('')
            const activeIndexTwo=ref('')
            const footerLogo=ref(footerbottomlogo)
            //点击菜单跳转
            const clickMenu=(item:string)=>{
                if(item==='PackagingDesignOverview' || item==='WorkProcessOverview'){
                    activeIndexTwo.value=item
                    activeIndex.value='Overview'
                }else if(item=="CustomBottleDesign" || item=="Decoration" ||item=="Packaging" ||item=="Sourcing" )
                {
                    activeIndexTwo.value=item
                    activeIndex.value='Capabilities'
                }
                else if(item=="LiquorProductPackaging" || item=="WineProductPackaging" ||item=="BeerBeverageAndFoodProductPackaging" ||item=="OtherProductsPackaging" )
                {
                    activeIndexTwo.value=item
                    activeIndex.value='ProductCatalog'
                }
                else {
                    activeIndex.value=item
                    activeIndexTwo.value=item
                }
                router.push('/'+item)

            }
            const sy=()=>{
                router.push('/')
            }
            const cn_en=()=>{
                store.commit('getlang')
                if(sessionStorage.lang){
                    if(sessionStorage.lang=='en'){
                        sessionStorage.setItem('lang','cn')
                    }else {
                        sessionStorage.setItem('lang','en')
                    }
                }else {
                    sessionStorage.setItem('lang','cn')
                }
                router.go(0)
                console.log(sessionStorage.lang)
            }
            const handleSelect = (key: string, keyPath: string[]) => {
                console.log(key, keyPath)
            }

            const { t } = useI18n({
            })
            const logoUrl=ref(logo)
            const logWapper=ref('');
            return {
                logWapper,
                logoUrl,
                t,cn_en
                ,activeIndex
                ,handleSelect,clickMenu,
                activeIndexTwo,
                footerLogo,sy
                // locale,t
            }
        }
    }
