import { createStore } from 'vuex'

export default createStore({
  state: {
    lang:'cn'as string,
  },
  getters: {
  },
  mutations: {
    getlang(state){
      if(state.lang=='cn'){
        state.lang='en'
      }else {
        state.lang='cn'
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
