export default {
  "header_menu": {
    "Overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "Capabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capabilities"])},
    "ProductCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Catalog"])},
    "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "OurCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Company"])},
    "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
  },
  "OverviewHeader": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
    "PackagingDesignOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packaging Design Overview"])},
    "WorkProcessOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Process Overview"])}
  },
  "Capabilities": {
    "CustomBottleDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Bottle Design"])},
    "Decoration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decoration"])},
    "Packaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packaging"])},
    "Sourcing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sourcing"])}
  },
  "ProductCatalog": {
    "LiquorProductPackaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquor Product Packaging"])},
    "WineProductPackaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wine Product Packaging"])},
    "BeerBeverageAndFoodProductPackaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beer, Beverage And Food Product Packaging"])},
    "OtherProductsPackaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Products Packaging"])}
  },
  "CCBD": {
    "C1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Bottle Design"])},
    "C1ftitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capabilities"])},
    "C1content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A custom designed bottle is one of the best ways to have your product stand out on the store shelves. A custom bottle will show many characteristics of your product, luxury, elegance, masculinity, femininity, quality and many more."])},
    "C31title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capabilities"])},
    "C31content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also add unique design of embossed or debossed text and patterns to further distinguish your product."])},
    "C32content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Encore Packaging, we provide free custom bottle design service to help you achieve your goals."])}
  },
  "CD": {
    "CDtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decoration"])},
    "CD1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decoration"])},
    "CD1ftitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capabilities"])},
    "CD1content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you chose to use a stock bottle, it becomes very important that how to make your product different comparing to many other products in the same bottle shape."])},
    "CD3title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capabilities"])},
    "CD3content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Encore Packaging, we are capable to provide many options for decoration that will help you control your cost and deliver the message of your product."])}
  },
  "CP": {
    "CPtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packaging"])},
    "CP1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packaging"])},
    "CP1ftitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capabilities"])},
    "CP1content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are capable to provide both shipping cartons and custom design printed cartons for your product, shipping in knock down or packed with bottles inside.We also offer gift boxes for your promotional and holiday gift needs."])}
  },
  "CS": {
    "CStitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sourcing"])},
    "CS1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sourcing"])},
    "CS1ftitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capabilities"])},
    "CS1content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If our customer requires other packing materials we do not produce we can help in sourcing including screw caps, corks, labels, swing tops and many more."])}
  },
  "faq": {
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where are you located?"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are located in Shandong Yantai, where is famous for its wine and beer industries in China."])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are your business hours?"])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our business hours are 8:00am-5:00pm Monday to Friday but our online service operates 24hours 7days a week to answer customer inquire from all over the world."])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there a minimum requirement?"])},
    "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, the minimum purchase requirement varies by different product type, please talk to our sales representative for more information."])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which port do you use for export?"])},
    "c4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We mainly use Qingdao Port for exporting where is within a 3hours zone for trucking from most of our factory partners deliver to port for loading."])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the lead time for a custom product?"])},
    "c5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approximately 4-8weeks, once sample and all artwork are completed and approved."])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you help design custom molds?"])},
    "c6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, we have helped many customers to design and develop their own private molds, from different bottle shapes, to cartouches, special finishes, custom decorations."])},
    "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What sort of bottle decorations do you offer?"])},
    "c7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are many options, including silkscreen printing, frosting, decal, heat transfer printing, we can even make a custom color for you via coating applied to a clear bottle."])},
    "t8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you sell plain white or kraft cartons?"])},
    "c8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, we offer Knock-Down cartons as well as partitions, cartons can also be printed according to your requirement, please talk to us."])},
    "t9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can you arrange shipment directly to my warehouse?"])},
    "c9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, our freight and logistic partners will ship your order to wherever in the world you need them to go."])},
    "t10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are your bottles compliant with US Proposition 65?"])},
    "c10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, everything we ship to US is Prop 65 compliant and verified."])}
  },
  "ourcompany": {
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUR COMPANY"])},
    "t11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Encore PACKAGING"])},
    "t11f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Encore Packaging"])},
    "t1c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yantai Encore Packaging established in 2015 is a leading company in providing packaging solutions to Wine, Liquor, Beer, Food and Beverage industries.Major products including premium wine, liquor, beer, beverage glass bottles and printed corrugate cartons."])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our service"])},
    "t2f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We know glass bottles and we know cartons We help you building your brand"])},
    "t2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services cover from initial packaging design, manufacturing and delivery to customer’s warehousing or co-packing facilities, an entire supply chain solution provider that free customers up to concentrate on managing their own business. "])},
    "t31t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide customized services"])},
    "t31c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With a network of trusted partners, Encore Packaging provides all custom design needs to make customer’s product stands out on the store shelves, providing best packaging solution to grab consumers’ attention and make customer’s product shine in the sea of competition. Encore Packaging also help customers to achieve their business goals by providing cost control, production planning and efficient logistic services. "])},
    "t32t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["long-lasting partnership"])},
    "t32c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Encore Packaging, partnership with.customers is deeply valued, learning about customers’ business, evaluate customer’s needs. Weather customers need stock mold packaging or custom design packaging,  every step is carefully managed from design, manufacturing to final delivery. Encore Packaging’s professional team with rich expertise act as customers’ procurement, quality control and adviser to make it easy to complete customer’s plans. "])},
    "t51t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We treat customers as family"])},
    "t51c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encore Packaging treats all customers as friends and families, care about customers’ success and always treat customers in the best ways, value partnership with customers deeply and build a long-lasting partnership is a mission and key to Encore Packaging’s success. "])},
    "t61t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission Statement"])},
    "t61c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We aim to be the best packaging company in the industry, we committed in building a long-term partnership with our customers and suppliers, we contribute to our customer’s success by providing highest standard service and product which give customer’s product a competitive edge in the marketplace. "])}
  },
  "cu": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to work with us?"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are interested in working with us, please send us a message, our sales team will get connected with you to start our conversation."])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items marked * are essential information."])},
    "f1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "f2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company Name"])},
    "f3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["industry"])},
    "f4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "f5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])},
    "f6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country / city"])},
    "f7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested Product"])},
    "f71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wine bottles"])},
    "f72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquor bottles"])},
    "f73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beer bottles"])},
    "f74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beverage bottles"])},
    "f75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food glass bottles"])},
    "f76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrugated carton"])},
    "f77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "f8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your specific needs"])},
    "fc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submit"])}
  },
  "i": {
    "ibannertitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Service Custom Packaging Solution Provider"])},
    "ibannercontent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Encore Packaging we concentrate on providing custom designed packaging solutions to our customers.Our services cover from initial packaging design, sampling, production management, quality control, sourcing, warehousing to delivery at customer’s facility, a real full supply chain service specialist."])},
    "i2t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Choose Us"])},
    "i2t1f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["explore Encore Packaging"])},
    "i21t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are the packaging expert you need"])},
    "i21c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to make your product stand out on the store shelves? We will help you create custom packaging solution to make your product unique and recognition improved to your customers."])},
    "i22t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Trusted network of partners"])},
    "i22c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With over ten years of experience in business, we have built a network of trusted partners to meet all your packaging need, doesn’t matter if you need glass packaging, carton packaging or plastic packaging materials we have it all."])},
    "i23t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Professional Team"])},
    "i23c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team of sales, customer services and designers will walk with you through the entire process, provide suggestions and solutions at every stage of the process to deliver your satisfied packaging product."])},
    "i31t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquor Product Packaging"])},
    "i32t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wine Product Packaging"])},
    "i33t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beer, Beverage and Food Product Packaging"])},
    "i34t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Product Packaging"])},
    "i41t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What makes us different"])},
    "i41tf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["explore Encore Packaging"])},
    "i411t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We treat customers as family"])},
    "i411c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We treat all our customers as friends and families, we care about our customer’s success and always treat them the best ways"])},
    "i412t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide highest quality products and services"])},
    "i412c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We always put our foot in customer’s shores, think what is the best option for their business, help them to control their cost and meet their target"])},
    "i413t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committed to build long term partnerships"])},
    "i413c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We value our partnership with our customers and suppliers deeply and building a long-lasting partnership is key to our success"])},
    "i5t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case Study"])},
    "i5tf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["explore Encore Packaging"])},
    "i5c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papa’s Pilar is a Rum Brand from Florida, USA"])},
    "i5c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We managed to build their custom glass bottle mold in China, manufactured their glass bottles from initial one container."])},
    "i5c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We help them to make production plan and control cost."])},
    "i6t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communicate with us"])},
    "i6c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are interested in our business, please contact us. We will provide you with a satisfactory solution."])},
    "il": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
    "ic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view more"])},
    "iy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click here to chat"])},
    "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation of"])}
  },
  "pdo": {
    "Cws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communicate with us"])},
    "cbannertitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PACKAGING DESIGN OVERVIEW"])},
    "1t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PACKAGING DESIGN OVERVIEW"])},
    "1c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doesn’t matter customer shop in person or on line, packaging give them the first impression of your product, we help our customer to create unique custom packaging design that deliver the functional needs and showing of their product value."])},
    "2t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Think from the customer's point of view"])},
    "2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding is so important to every product, we study deeply about our customer’s creation and their value, provide them with the best option to enhance their brand vision and help develop their long-term sustainable goals."])},
    "3t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PACKAGING DESIGN AND ENGINEERING"])},
    "3c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team excels at packaging design and engineering, we work closely with our partners provide stock or custom design packaging product to bring your product to life and help you achieve your business goals.\n"])}
  },
  "wpo": {
    "bt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Process Overview"])},
    "1t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
    "1tf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about encore"])}
  }
}