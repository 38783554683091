export default {
  "header_menu": {
    "Overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品概览"])},
    "Capabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的能力"])},
    "ProductCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品目录"])},
    "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常见问题"])},
    "OurCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的公司"])},
    "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])}
  },
  "OverviewHeader": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
    "PackagingDesignOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包装设计概述"])},
    "WorkProcessOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作进程概述"])}
  },
  "Capabilities": {
    "CustomBottleDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定制瓶设计"])},
    "Decoration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["深加工装饰"])},
    "Packaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外包装"])},
    "Sourcing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["采购"])}
  },
  "ProductCatalog": {
    "LiquorProductPackaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烈性酒类产品包装"])},
    "WineProductPackaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["红酒类产品包装"])},
    "BeerBeverageAndFoodProductPackaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["啤酒，饮料和食品类产品包装"])},
    "OtherProductsPackaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他类产品包装"])}
  },
  "CCBD": {
    "C1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定制瓶设计"])},
    "C1ftitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能"])},
    "C1content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定制设计的瓶子是让您的产品在商店货架上脱颖而出的最好方法之一。一个定制的瓶子将展示您产品的许多特点，豪华，优雅，男性化，女性化，高品质还有更多"])},
    "C31title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能"])},
    "C31content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您也可以在瓶子上添加独特的设计浮雕或镂雕文字和图案进一步使您的产品与众不同。"])},
    "C32content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在恩格包装，我们提供免费的定制瓶设计服务，以帮助您实现您的目标。"])}
  },
  "CD": {
    "CDtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["深加工装饰"])},
    "CD1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["深加工装饰"])},
    "CD1ftitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能"])},
    "CD1content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当你选择使用库存瓶子时，如何使你的产品与其他相同瓶型产品不同就变得非常重要了。"])},
    "CD3title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能"])},
    "CD3content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在恩格包装，我们能够提供许多装饰选项，帮助您控制成本并传递您的产品的信息。"])}
  },
  "CP": {
    "CPtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外包装"])},
    "CP1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外包装"])},
    "CP1ftitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能"])},
    "CP1content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们有能力为您的产品提供运输纸箱和定制设计印刷纸箱，折叠运输或装载瓶子运输。我们还提供礼盒，以满足您的促销和节日礼品需求。"])}
  },
  "CS": {
    "CStitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["采购"])},
    "CS1title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["采购"])},
    "CS1ftitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能"])},
    "CS1content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果我们的客户需要其他我们不生产的包装材料，我们可以帮助采购, 包括螺帽，软木塞，标签，摇盖还有更多。"])}
  },
  "faq": {
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你们公司在哪里？"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们位于山东烟台，一个在中国因其葡萄酒和啤酒行业而驰名的城市。"])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你们的工作时间是什么？"])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们正常的工作时间是早八点到晚五点周一至周五，但是我们提供24小时在线服务回答来自世界各地客户的咨询。"])},
    "t3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是否有最低起订量要求？"])},
    "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的，根据不同产品最低起订量是不同的，请跟我们的销售代表联系获取详细信息。"])},
    "t4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你们用哪个港口出口？"])},
    "c4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们主要用青岛港出口，我们大部分的合作工厂都在青岛港三小时陆运范围内以方便装船。"])},
    "t5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定制产品的交货期大概是多久？"])},
    "c5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大概4-8周的时间，需要前期样品和图纸都经过客户确认。"])},
    "t6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你们可以协助设计定制产品么？"])},
    "c6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的，我们帮助过很多的客户设计和创建他们独有的模具，从不同的瓶型到雕花，不同瓶口，定制深加工我们都可以提供。"])},
    "t7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你们提供什么样的装饰服务？"])},
    "c7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有很多不同的选项包括丝网印刷，蒙砂，烤花，热转印，我们甚至可以通过在光瓶上喷涂为您定制特有的颜色。"])},
    "t8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你们销售普通白箱和黄板箱么？"])},
    "c8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的，我们提供折叠运输纸箱和插格，纸箱也可以根据您的要求进行印刷，请跟我们联系。"])},
    "t9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你们可以直接送货到我的仓库么？"])},
    "c9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的，我们的物流合作伙伴可以在您需要的时候送货至世界上的任何地点。"])},
    "t10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你们的瓶子是否符合美国65号法案要求？"])},
    "c10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的，所有我们送至美国的产品都遵循65号法案并可以提供证明。"])}
  },
  "ourcompany": {
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司简介"])},
    "t11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎来到恩格包装"])},
    "t11f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎来到恩格包装"])},
    "t1c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烟台恩格包装成立于2015年是专注于为红酒，烈性酒，啤酒，食品和饮料行业提供包装服务的行业领先公司。 "])},
    "t2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务项目"])},
    "t2f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要产品包括高档红酒，烈性酒，啤酒，饮料玻璃瓶和印刷瓦楞纸箱。"])},
    "t2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要服务包括初始包装设计，产品生产到送货至客户仓库以及协作罐装场地，是一个全方位的供应链服务提供者，使客户能够专注于管理他们的生意。"])},
    "t31t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供定制服务"])},
    "t31c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恩格包装拥有可信赖的合作伙伴网络，提供所有定制设计需求，使客户的产品在货架上能脱颖而出，通过最佳的包装解决方案，抓住消费者的注意力，让客户的产品在竞争的海洋中胜出。恩格包装通过提供成本控制，生产计划和高效的物流服务来帮助他们的客户实现他们的商业目标。"])},
    "t32t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长期伙伴关系"])},
    "t32c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恩格包装珍视与客户的合作伙伴关系，我们持续了解客户的业务，评估客户的需求。无论客户需要库存包装产品或定制设计包装产品，从设计、制造到最终交付的每一步都是精心管理的。恩格包装的专业团队，专业知识丰富，可以作为客户采购专员，质量控制和顾问，使客户的计划能更容易的实现。"])},
    "t51t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们视客户如家人"])},
    "t51c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恩格包装视所有客户为朋友和家人，我们关心客户的成功，始终以最好的方式对待客户，珍视与客户的合作关系，努力建立一个持久的伙伴关系是恩格包装的使命和成功的关键。"])},
    "t61t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的使命"])},
    "t61c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的目标是成为行业中最优秀的包装公司，我们致力于与我们的客户和供应商建立长期的合作伙伴关系，我们通过提供最高水平的服务和产品，使客户的产品在市场上具有竞争优势，为客户的成功做出我们的贡献。 "])}
  },
  "cu": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
    "t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如何与我们合作?"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您有兴趣与我们合作，请给我们发送信息，我们的销售团队将尽快与您联系并开始我们的交流"])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标有*的项目为必要信息。"])},
    "f1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
    "f2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司名称"])},
    "f3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行业"])},
    "f4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱"])},
    "f5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
    "f6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家/国家和城市"])},
    "f7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要求的产品"])},
    "f71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["酒瓶/美酒佳酿"])},
    "f72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白酒瓶"])},
    "f73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["啤酒瓶"])},
    "f74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["饮料瓶"])},
    "f75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调味瓶"])},
    "f76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瓦楞纸箱"])},
    "f77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
    "f8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请问你具体需要哪几种"])},
    "fc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])}
  },
  "i": {
    "ibannertitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一站式定制包装服务专家"])},
    "ibannercontent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恩格包装专注于为客户提供定制包装解决方案。我们的服务覆盖初始包装设计，提供样品, 生产管理，质量控制，采购，仓储及送货到客户生产地址，一个真正的全供应链服务专家。"])},
    "i2t1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为什么选择我们"])},
    "i2t1f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索恩格包装"])},
    "i21t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们是你需要的包装专家"])},
    "i21c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["想让你的产品在商店货架上脱颖而出?我们将帮助您创建定制包装解决方案，使您的产品独特，提高客户的认知度。"])},
    "i22t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们值得信任的合作伙伴网络"])},
    "i22c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["凭借十多年的从业经验，我们已经建立了一个值得信赖的合作伙伴网络，以满足您所有的包装需求，无论您需要玻璃包装，纸箱包装或塑料包装材料，我们都可以提供。"])},
    "i23t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的专业团队"])},
    "i23c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的销售团队、客户服务团队和设计师将全程陪伴您，在每一个环节提供建议和解决方案，以交付您满意的包装产品。"])},
    "i31t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["烈性酒类产品包装"])},
    "i32t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["红酒类产品包装"])},
    "i33t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["啤酒，饮料和食品类产品包装"])},
    "i34t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他类产品包装"])},
    "i41t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是什么让我们与众不同"])},
    "i41tf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索恩格包装"])},
    "i411t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们视客户如家人一般"])},
    "i411c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们把所有的客户视为朋友和家人，我们关心客户的成功，总是以最好的方式对待他们。"])},
    "i412t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供最优质的产品和服务"])},
    "i412c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们总是站在客户的立场上，思考对他们的业务来说什么是最好的选择，帮助他们控制成本，实现他们的目标。"])},
    "i413t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["致力于建立长期的合作伙伴关系"])},
    "i413c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们非常珍视与客户以及供应商的合作关系，建立持久的合作关系是我们成功的关键。"])},
    "i5t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户案例"])},
    "i5tf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索恩格包装"])},
    "i5c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papa 's Pilar是来自美国佛罗里达州的朗姆酒品牌"])},
    "i5c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们设法在中国建立他们的定制玻璃瓶模具，从最初的一个容器制造他们的玻璃瓶。"])},
    "i5c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们帮助他们制定生产计划和控制"])},
    "i6t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与我们交流"])},
    "i6c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您对我们的业务感兴趣，请与我们联系。我们将为您提供满意的解决方案。"])},
    "il": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
    "ic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
    "iy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与我们联系"])},
    "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评价"])}
  },
  "pdo": {
    "Cws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与我们交流"])},
    "cbannertitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包装设计概述"])},
    "1t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包装设计概述"])},
    "1c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无论客户是亲自购物还是在线购物，包装都能让他们对您的产品产生第一印象，我们帮助客户创造独特的定制包装设计，传递功能需求，展示他们的产品价值。 "])},
    "2t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["站在顾客的角度考虑问题"])},
    "2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无论客户是亲自购物还是在线购物，包装都能让他们对您的产品产生第一印象，我们帮助客户创造独特的定制包装设计，传递功能需求，展示他们的产品价值。"])},
    "3t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包装设计与工程"])},
    "3c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的团队擅长包装设计和研发，我们与我们的合作伙伴紧密协作，提供存货或者定制包装产品，将您的产品带入生活，帮助您实现您的商业目标。"])}
  },
  "wpo": {
    "bt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作流程概述"])},
    "1t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["流程"])},
    "1tf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于恩格"])}
  }
}