import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import indexSy from '@/views/indexSy.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'indexsy',
    component: indexSy,
    children:[
      {
        path:'/',
        name:'i',
        component:()=>import('../views/i.vue')
      },
      {
        path:'/aboutus',
        name:'aboutus',
        component:()=>import('../views/aboutUs.vue')
      },
      {
        path:'/services',
        name:'services',
        component:()=>import('../views/services.vue')
      },
      {
        path:'/contactUs',
        name:'contactUs',
        component:()=>import('../views/contactUs.vue')
      },
      {
        path:'/PackagingDesignOverview',
        name:'PackagingDesignOverview',
        component:()=>import('../views/overviewOWPO.vue')
      },
      {
        path:'/WorkProcessOverview',
        name:'WorkProcessOverview',
        component:()=>import('../views/overviewPDO.vue')
      },
      {
        path:'/CustomBottleDesign',
        name:'CustomBottleDesign',
        component:()=>import('../views/CustomBottleDesignP.vue')
      },
      {
        path:'/Decoration',
        name:'Decoration',
        component:()=>import('../views/DecorationP.vue')
      },{
        path:'/Packaging',
        name:'Packaging',
        component:()=>import('../views/PackagingP.vue')
      },
      {
        path:'/Sourcing',
        name:'Sourcing',
        component:()=>import('../views/SourcingP.vue')
      },
      {
        path:'/LiquorProductPackaging',
        name:'LiquorProductPackaging',
        component:()=>import('../views/LiquorProductPackagingP.vue')
      },
      {
        path:'/WineProductPackaging',
        name:'WineProductPackaging',
        component:()=>import('../views/WineProductPackagingP.vue')
      },
      {
        path:'/BeerBeverageAndFoodProductPackaging',
        name:'BeerBeverageAndFoodProductPackaging',
        component:()=>import('../views/BeerBeverageAndFoodProductPackagingP.vue')
      },
      {
        path:'/OtherProductsPackaging',
        name:'OtherProductsPackaging',
        component:()=>import('../views/OtherProductsPackagingP.vue')
      },
      {
        path:'/FAQ',
        name:'FAQ',
        component:()=>import('../views/FAQ.vue')
      },
      {
        path:'/OurCompany',
        name:'OurCompany',
        component:()=>import('../views/OurCompany.vue')
      },
      {
        path:'/ContactUs',
        name:'ContactUs',
        component:()=>import('../views/aboutUs.vue')
      }

    ]
  },
  // {
  //   path:'/app',
  //   name:'app',
  //   component:()=>import('../views/ls.vue')
  // },
  {
    path:'/mt',
    name:'mt',
    component:()=>import('../views/maptest.vue')
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach( (to,from)=>{
//   if(window.screen.availWidth<900){
//     router.push('/app')
//   }else {
//     return
//   }
// });

export default router
