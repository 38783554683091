import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-plus/dist/index.css'
import elementUi from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import i18n from './i18n'
import { ElCollapseTransition } from 'element-plus'
import 'element-plus/theme-chalk/base.css'
import './font/font.css'
import {createI18n} from "vue-i18n";
import AMapLoader from "@amap/amap-jsapi-loader";
// const i18n =createI18n({
//     legacy: false,
//     messages: {
//         CN: require('@/locales/cn.json')
//     }
// })
createApp(App).use(i18n).use(store).use(router).use(elementUi).use(ElCollapseTransition).mount('#app')
